import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import mapStyles from "src/components/helpers/googleMapStyles";
import { MapMarker } from "../commonComponents";

const extractAddress = (obj) => {
  const a = obj.fieldPartnersAddress;
  return `${a.addressLine1} ${a.locality} ${a.postalCode}, ${a.countryCode}`;
};

/**
 * @param {Array of Objects} headquarters - [{entity: {fieldPartnersAddress: {addressLine1: String,locality: String, ...}}}]
 */

const CreateMapData = ({headquarters}) => {
  const [mapAddress, setAddress] = useState([]);

  const initGeocoder = ({ maps, map }) => {
    const Geocoder = new maps.Geocoder();
    if(!!headquarters?.length){
      headquarters.forEach((add, index) => {
        let address = extractAddress(add);

        Geocoder.geocode({ address: address }, (results, status) => {
          if (status === maps.GeocoderStatus.OK) {
            results[0]["title"] = add?.title
            setAddress(oldArray => [...oldArray, results[0]]);
          }
        });
      })
      
    }
  }
  return(
    <section className="section">
      <div className="map-section mb-5 mt-5 is-edge-to-edge">
        <GoogleMapReact
          defaultCenter={{ lat: 39.45, lng: -45.39 }}
          defaultZoom={2}
          bootstrapURLKeys={{
            key: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={initGeocoder}
          options={{ styles: mapStyles }}
        >
          {!!mapAddress?.length && (
            mapAddress.map((add, index) => {
              return(
                <MapMarker
                  lat={add.geometry.location.lat()}
                  lng={add.geometry.location.lng()}
                  text={add?.title || add?.formatted_address}
                />
              )
            })
            
          )}
          
        </GoogleMapReact>
      </div>
  </section>
  )
}
export default CreateMapData;