import React from 'react'
import HeadquartersCard from "src/components/locations/HeadquartersCard";
import CreateMapData from "src/components/helpers/CreateMapData";
import "src/scss/components/_locations.scss";

/**
 * @param {Array of Objects} entity - [{fieldHeadquarter: Array of Objects}}]
 */

const Locations = ({ entity }) => {
    const headquarters = entity?.relationships?.fieldHeadquarter
    const withMap = entity?.fieldShowMap
    if (!(Array.isArray(headquarters) && headquarters?.length)) return (<></>)

    return (
      <div className="locations-container">
        <div className="content-wrapper tw-text-base tw-leading-6 tw-w-full tw-pt-[61px] lg:tw-text-[18px]">
          <h2 className="title-3 lg:tw-mb-4 tw-mb-[18px]">Headquarters</h2>
          <div className="tw-columns is-multiline is-mobile">
            {headquarters.map((location, index) => {
              return (
                <div className="address-box lg:tw-w-1/2 tw-w-full">
                  <HeadquartersCard key={index} headquarter={location} />
                </div>
              );
            })}
          </div>
        </div>

        {withMap && <CreateMapData headquarters={headquarters} />}
      </div>
    );
}

export default Locations;